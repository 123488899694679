import React from 'react';

export default function AboutBanner() {
  return (
    <div class='page-heading header-text'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h1>About Us</h1>
            <span>We have over 5 years of experience</span>
          </div>
        </div>
      </div>
    </div>
  );
}
