import Header from './common/header/Header';
import Footer from './common/footer/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import Routings from './common/route/Rourtings';

function App() {
  return (
    <Router>
      <Header />
      <Routings />
      <Footer />
    </Router>
  );
}

export default App;
