import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutPage() {
  return (
    <>
      <div className='more-info about-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='more-info-content'>
                <div className='row'>
                  <div className='col-md-6 align-self-center'>
                    <div className='right-content'>
                      <span>our solid background on finance</span>
                      <h2>
                        Get to know about <em>our company</em>
                      </h2>
                      <p>
                        Finvestza Finance, Jaipur based Financial Service Firm
                        primarily focusing on providing Loans on pan India
                        basis. We help to provide finance to every individual a
                        very easy process and to give best services to every
                        earning Individuals.
                        <br />
                        <br />
                        <br />
                        <br />
                      </p>
                      <Link to='/about' className='filled-button'>
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='left-image'>
                      <img src='assets/images/about-image.jpg' alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='team'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  Our team <em>members</em>
                </h2>
                <span>Suspendisse a ante in neque iaculis lacinia</span>
              </div>
            </div>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <div className='team-item'>
                <img src='assets/images/team_03.jpg' alt='' />
                <div className='down-content'>
                  <h4>Harsh Vardhan</h4>
                  <span>CEO &amp; Founder</span>
                  <p>
                    In sem sem, dapibus non lacus auctor, ornare sollicitudin
                    lacus. Aliquam ipsum urna, semper quis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='fun-facts'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='left-content'>
                <span>Mission And Vision Of Finvestza Finance</span>
                <h2>
                  Our solutions for your <em>business growth</em>
                </h2>
                <p>
                  To make financial services easily accessible to every citizen
                  of India. To become one of the top financial service
                  distributors by creating an ideal environment that benefits
                  all participants of the Financial Ecosystem, i.e. Service
                  Providers, Representatives and Customers.
                  <br />
                  <br />
                </p>
                <Link to='/about' className='filled-button'>
                  Read More
                </Link>
              </div>
            </div>
            <div className='col-md-6 align-self-center'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>945</div>
                    <div className='count-title'>Work Hours</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>1280</div>
                    <div className='count-title'>Great Reviews</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>578</div>
                    <div className='count-title'>Projects Done</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>26</div>
                    <div className='count-title'>Awards Won</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='testimonials'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  What they say <em>about us</em>
                </h2>
                <span>testimonials from our greatest clients</span>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='owl-testimonials owl-carousel'>
                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>George Walker</h4>
                    <span>Chief Financial Analyst</span>
                    <p>
                      "Nulla ullamcorper, ipsum vel condimentum congue, mi odio
                      vehicula tellus, sit amet malesuada justo sem sit amet
                      quam. Pellentesque in sagittis lacus."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>John Smith</h4>
                    <span>Market Specialist</span>
                    <p>
                      "In eget leo ante. Sed nibh leo, laoreet accumsan euismod
                      quis, scelerisque a nunc. Mauris accumsan, arcu id ornare
                      malesuada, est nulla luctus nisi."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>David Wood</h4>
                    <span>Chief Accountant</span>
                    <p>
                      "Ut ultricies maximus turpis, in sollicitudin ligula
                      posuere vel. Donec finibus maximus neque, vitae egestas
                      quam imperdiet nec. Proin nec mauris eu tortor consectetur
                      tristique."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>Andrew Boom</h4>
                    <span>Marketing Head</span>
                    <p>
                      "Curabitur sollicitudin, tortor at suscipit volutpat, nisi
                      arcu aliquet dui, vitae semper sem turpis quis libero.
                      Quisque vulputate lacinia nisl ac lobortis."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
