import React, { useState } from 'react';

export default function LoanCalculator() {
  const [type, setType] = useState(''),
    [amount, setAmount] = useState(''),
    [duration, setDuration] = useState(''),
    [finalValue, setFinalValue] = useState('');

  const send = (event) => {
    event.preventDefault();
    // Do something here...
    //sendMail({ name, email, message, subject });
    clearForm();
  };

  const clearForm = () => {
    setType('');
    setAmount('');
    setDuration('');
    setFinalValue('');
  };
  return (
    <div className='callback-form'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-heading'>
              <h2>
                Loan <em>calculator</em>
              </h2>
              <span>You will find that we provide the best values</span>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='contact-form'>
              <form id='calculator' onSubmit={send}>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='type'
                        type='text'
                        className='form-control'
                        id='type'
                        placeholder='Type of Loan'
                        required=''
                        value={type}
                        onChange={(event) => setType(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='amount'
                        type='text'
                        className='form-control'
                        id='amount'
                        pattern='[^ @]*@[^ @]*'
                        placeholder='Amount of loan'
                        required=''
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='duration'
                        type='text'
                        className='form-control'
                        id='duration'
                        placeholder='Duration'
                        required=''
                        value={duration}
                        onChange={(event) => setDuration(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-12'>
                    <fieldset>
                      <textarea
                        name='finalValue'
                        rows='6'
                        className='form-control'
                        id='finalValue'
                        placeholder='Final Value'
                        required=''
                        value={finalValue}
                        onChange={(event) => setFinalValue(event.target.value)}
                      ></textarea>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
