import React, { Fragment } from 'react';
import HomeBanner from '../header/banner/HomeBanner';
import HomePage from '../../page/HomePage';
import { Switch, Route } from 'react-router-dom';
import AboutBanner from '../header/banner/AboutBanner';
import AboutPage from '../../page/AboutPage';
import ErrorPageBanner from '../header/banner/ErrorPageBanner';
import CarLoanService from '../../page/services/CarLoanService';
import ServicesBanner from '../header/banner/ServicesBanner';
import ServicesPage from '../../page/ServicesPage';
import BusinessLoans from '../../page/services/BusinessLoans';
import CommercialVehicleFinance from '../../page/services/CommercialVehicleFinance';
import ConstructionLoan from '../../page/services/ConstructionLoan';
import CreditCards from '../../page/services/CreditCards';
import GovernmentLoansMsmeSidbi from '../../page/services/GovernmentLoansMsmeSidbi';
import HomeImprovementLoan from '../../page/services/HomeImprovementLoan';
import HomeLoan from '../../page/services/HomeLoan';
import LoanAgainstProperty from '../../page/services/LoanAgainstProperty';
import MortgageLoan from '../../page/services/MortgageLoan';
import OdCc from '../../page/services/OdCc';
import PersonalLoan from '../../page/services/PersonalLoan';
import PlotLoan from '../../page/services/PlotLoan';
import PrivateFunding from '../../page/services/PrivateFunding';
import ProjectLoans from '../../page/services/ProjectLoans';
import PurchaseConstructionLoan from '../../page/services/PurchaseConstructionLoan';
import UsedCarLoan from '../../page/services/UsedCarLoan';
import ContactBanner from '../header/banner/ContactBanner';
import ContactPage from '../../page/ContactPage';
import LoanCalculatorPage from '../../page/LoanCalculatorPage';

export default function Routings() {
  const ERROR_MSG = 'Opps! Page you are looking for is not available.',
    NOT_FOUND_ERROR = 'Page Not Found';
  return (
    <Switch>
      <Route
        exact
        path='/'
        render={(props) => (
          <>
            <HomeBanner></HomeBanner>
            <HomePage></HomePage>
          </>
        )}
      />

      <Route exact path='/services/business-loans' component={BusinessLoans} />
      <Route exact path='/services/car-loan' component={CarLoanService} />
      <Route
        exact
        path='/services/commercial-vehicle-finance'
        component={CommercialVehicleFinance}
      />
      <Route
        exact
        path='/services/construction-loan'
        component={ConstructionLoan}
      />
      <Route exact path='/services/credit-cards' component={CreditCards} />
      <Route
        exact
        path='/services/government-loans-msme-sidbi'
        component={GovernmentLoansMsmeSidbi}
      />
      <Route
        exact
        path='/services/home-improvement-loan'
        component={HomeImprovementLoan}
      />
      <Route exact path='/services/home-loan' component={HomeLoan} />
      <Route
        exact
        path='/services/loan-against-property'
        component={LoanAgainstProperty}
      />
      <Route exact path='/services/mortgage-loan' component={MortgageLoan} />
      <Route exact path='/services/od-cc' component={OdCc} />
      <Route exact path='/services/personal-loan' component={PersonalLoan} />
      <Route exact path='/services/plot-loan' component={PlotLoan} />
      <Route
        exact
        path='/services/private-funding'
        component={PrivateFunding}
      />
      <Route exact path='/services/project-loans' component={ProjectLoans} />
      <Route
        exact
        path='/services/purchase-construction-loan'
        component={PurchaseConstructionLoan}
      />
      <Route exact path='/services/used-car-loan' component={UsedCarLoan} />

      {/** Below route should be the last ta */}
      <Route
        exact
        path='/services'
        render={(props) => (
          <>
            <ServicesBanner /> <ServicesPage />
          </>
        )}
      />

      <Route
        exact
        path='/about'
        render={(props) => (
          <>
            <AboutBanner /> <AboutPage />
          </>
        )}
      />

      <Route exact path='/calculator' component={LoanCalculatorPage} />

      <Route
        exact
        path='/contact'
        render={(props) => (
          <>
            <ContactBanner /> <ContactPage />
          </>
        )}
      />

      <Route
        render={(props) => (
          <>
            <ErrorPageBanner message={ERROR_MSG} error={NOT_FOUND_ERROR} />
            {/* <NotFound message={ERROR_MSG} error={NOT_FOUND_ERROR} /> */}
          </>
        )}
      />
    </Switch>
  );
}
