import React from 'react';
import { Link } from 'react-router-dom';
import CallBackForm from './forms/CallBackForm';

export default function HomePage() {
  return (
    <>
      <div className='request-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h4>Quick Apply ?</h4>
              <span>Get call from us at earliest for your request.</span>
            </div>
            <div className='col-md-4'>
              <Link
                to='/contact'
                onClick={() => window.scrollTo(0, 0)}
                className='border-button'
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='services'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  Financial <em>Services</em>
                </h2>
                <span>
                  We provide all types of Loans. Checkout our all services here.
                </span>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='service-item'>
                <img src='assets/images/service_01.jpg' alt='' />
                <div className='down-content'>
                  <h4>Home Loan</h4>
                  <p>
                    A home loan is a loan that have endues to purchase of
                    residential flat, bungalow, residential plot.
                    <br />
                    <br />
                    <br />
                  </p>
                  <Link
                    to='/services/home-loan'
                    onClick={() => window.scrollTo(0, 0)}
                    className='filled-button'
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='service-item'>
                <img src='assets/images/service_02.jpg' alt='' />
                <div className='down-content'>
                  <h4>Car Loan</h4>
                  <p>
                    A car loan is a loan that have endues purchase of new car.
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                  <Link
                    to='/services/car-loan'
                    onClick={() => window.scrollTo(0, 0)}
                    className='filled-button'
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='service-item'>
                <img src='assets/images/service_03.jpg' alt='' />
                <div className='down-content'>
                  <h4>Personal Loan</h4>
                  <p>
                    For any urgent cash requirement, we prefer personal loan. It
                    can be taken for any general purposes like education,
                    reconstruction of property/home renovation, a wedding
                    expense, vacation etc
                  </p>
                  <Link
                    to='/services/personal-loan'
                    onClick={() => window.scrollTo(0, 0)}
                    className='filled-button'
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='request-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h4>What more we serve?</h4>
              <span>Checkout all of our services.</span>
            </div>
            <div className='col-md-4'>
              <Link
                to='/services'
                onClick={() => window.scrollTo(0, 0)}
                className='border-button'
              >
                All Services
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='fun-facts'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='left-content'>
                <span>Lorem ipsum dolor sit amet</span>
                <h2>
                  Our solutions for your <em>business growth</em>
                </h2>
                <p>
                  Pellentesque ultrices at turpis in vestibulum. Aenean pretium
                  elit nec congue elementum. Nulla luctus laoreet porta.
                  Maecenas at nisi tempus, porta metus vitae, faucibus augue.
                  <br />
                  <br />
                  Fusce et venenatis ex. Quisque varius, velit quis dictum
                  sagittis, odio velit molestie nunc, ut posuere ante tortor ut
                  neque.
                </p>
                <Link
                  to='/about'
                  onClick={() => window.scrollTo(0, 0)}
                  className='filled-button'
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className='col-md-6 align-self-center'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>945</div>
                    <div className='count-title'>Work Hours</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>1280</div>
                    <div className='count-title'>Great Reviews</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>578</div>
                    <div className='count-title'>Projects Done</div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='count-area-content'>
                    <div className='count-digit'>26</div>
                    <div className='count-title'>Awards Won</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='more-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='more-info-content'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='left-image'>
                      <img src='assets/images/more-info.jpg' alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 align-self-center'>
                    <div className='right-content'>
                      <span>Who we are</span>
                      <h2>
                        Get to know about <em>our company</em>
                      </h2>
                      <p>
                        Curabitur pulvinar sem a leo tempus facilisis. Sed non
                        sagittis neque. Nulla conse quat tellus nibh, id
                        molestie felis sagittis ut. Nam ullamcorper tempus ipsum
                        in cursus
                        <br />
                        <br />
                        Praes end at dictum metus. Morbi id hendrerit lectus,
                        nec dapibus ex. Etiam ipsum quam, luctus eu egestas
                        eget, tincidunt
                      </p>
                      <Link
                        to='/about'
                        onClick={() => window.scrollTo(0, 0)}
                        className='filled-button'
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='testimonials'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  What they say <em>about us</em>
                </h2>
                <span>testimonials from our greatest clients</span>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='owl-testimonials owl-carousel'>
                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>George Walker</h4>
                    <span>Chief Financial Analyst</span>
                    <p>
                      "Nulla ullamcorper, ipsum vel condimentum congue, mi odio
                      vehicula tellus, sit amet malesuada justo sem sit amet
                      quam. Pellentesque in sagittis lacus."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>John Smith</h4>
                    <span>Market Specialist</span>
                    <p>
                      "In eget leo ante. Sed nibh leo, laoreet accumsan euismod
                      quis, scelerisque a nunc. Mauris accumsan, arcu id ornare
                      malesuada, est nulla luctus nisi."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>David Wood</h4>
                    <span>Chief Accountant</span>
                    <p>
                      "Ut ultricies maximus turpis, in sollicitudin ligula
                      posuere vel. Donec finibus maximus neque, vitae egestas
                      quam imperdiet nec. Proin nec mauris eu tortor consectetur
                      tristique."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>

                <div className='testimonial-item'>
                  <div className='inner-content'>
                    <h4>Andrew Boom</h4>
                    <span>Marketing Head</span>
                    <p>
                      "Curabitur sollicitudin, tortor at suscipit volutpat, nisi
                      arcu aliquet dui, vitae semper sem turpis quis libero.
                      Quisque vulputate lacinia nisl ac lobortis."
                    </p>
                  </div>
                  <img src='http://placehold.it/60x60' alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallBackForm />
    </>
  );
}
