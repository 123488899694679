import React from 'react';
import { Link } from 'react-router-dom';
import CommonBanner from '../../common/header/banner/CommonBanner';

export default function CommercialVehicleFinance() {
  return (
    <>
      <CommonBanner
        title='Commercial Vehicle Finance'
        subtitle='Finance your dream commercial vehicle'
      />

      <div className='more-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='more-info-content'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='left-image'>
                      <img src='/assets/images/more-info.jpg' alt='Car Loan' />
                    </div>
                  </div>
                  <div className='col-md-6 align-self-center'>
                    <div className='right-content'>
                      <span>Finance your dream commercial vehicle</span>
                      <h2>
                        Commercial Vehicle <em>Finance</em>
                      </h2>
                      <p>
                        We offer loans on purchase of commercial vehicles with
                        multiple channel partners
                        <br />
                        <br />
                        We offer loans on purchase of commercial vehicles with
                        multiple channel partners
                      </p>
                      <Link to='/services' className='filled-button'>
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
