import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';

export default function Header() {
  return (
    <>
      {/** * Preloader Start */}
      <div id='preloader'>
        <div className='jumper'>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/** * Preloader End */}
      <div className='sub-header'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-9 col-xs-12'>
              <ul className='left-info'>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-map-marker'></i>E-11 JanakPuri 1st
                    Lalkothi, Jaipur
                  </Link>
                </li>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-clock-o'></i>Mon-Sat 09:00 AM - 06:00 PM
                  </Link>
                </li>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-phone'></i>+91 987-654-3210
                  </Link>
                </li>
              </ul>
            </div>
            <div className='col-md-3'>
              <ul className='right-icons'>
                <li>
                  <Link href='/contact'>
                    <i className='fa fa-facebook'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-twitter'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-linkedin'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/contact'>
                    <i className='fa fa-behance'></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header>
        <NavBar></NavBar>
      </header>
    </>
  );
}
