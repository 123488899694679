import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function HomeBanner() {
  var settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    dots: true,
    pauseOnDotsHover: true,
    cssEase: 'linear',
    // fade: true,
    draggable: false,
    prevArrow: <SliderButton button='PrevArrow' />,
    nextArrow: <SliderButton button='NextArrow' />,
  };

  return (
    <div className='main-banner header-text' id='top'>
      <Slider className='Modern-Slider' {...settings}>
        {/** * Item Start */}
        <div className='item item-1'>
          <div className='img-fill'>
            <div className='text-content'>
              <h6>we are ready to help you</h6>
              <h4>
                Financial Analysis
                <br />
                &amp; Consulting
              </h4>
              <p>
                To make financial services easily accessible to every citizen of
                India. To become one of the top financial service distributors
                by creating an ideal environment that benefits all participants
                of the Financial Ecosystem, i.e. Service Providers,
                Representatives and Customers.
              </p>
              <Link
                to='/contact'
                onClick={() => window.scrollTo(0, 0)}
                className='filled-button'
              >
                contact us
              </Link>
            </div>
          </div>
        </div>
        {/** * Item End */}
        {/** * Item Start */}
        <div className='item item-2'>
          <div className='img-fill'>
            <div className='text-content'>
              <h6>we are here to support you</h6>
              <h4>
                Accounting
                <br />
                &amp; Management
              </h4>
              <p>
                To make financial services easily accessible to every citizen of
                India. To become one of the top financial service distributors
                by creating an ideal environment that benefits all participants
                of the Financial Ecosystem, i.e. Service Providers,
                Representatives and Customers.
              </p>
              <Link
                to='/services'
                onClick={() => window.scrollTo(0, 0)}
                className='filled-button'
              >
                our services
              </Link>
            </div>
          </div>
        </div>
        {/** * Item End */}
        {/** * Item Start */}
        <div className='item item-3'>
          <div className='img-fill'>
            <div className='text-content'>
              <h6>we have a solid background</h6>
              <h4>
                Market Analysis
                <br />
                &amp; Statistics
              </h4>
              <p>
                To make financial services easily accessible to every citizen of
                India. To become one of the top financial service distributors
                by creating an ideal environment that benefits all participants
                of the Financial Ecosystem, i.e. Service Providers,
                Representatives and Customers.
              </p>
              <Link
                to='/about'
                onClick={() => window.scrollTo(0, 0)}
                className='filled-button'
              >
                learn more
              </Link>
            </div>
          </div>
        </div>
        {/** * Item End */}
      </Slider>
    </div>
  );
}

function SliderButton({ button, onClick }) {
  return <button className={button} onClick={onClick}></button>;
}
