import React from 'react';

export default function CommonBanner({
  title = 'The Page Title',
  subtitle = 'This is subtitle. Keep your little description here',
}) {
  return (
    <div class='page-heading header-text'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h1>{title}</h1>
            <span>{subtitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
