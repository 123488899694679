import React from 'react';

export default function ErrorPageBanner({ error, message }) {
  return (
    <div class='page-heading header-text'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h1>{error}</h1>
            <span>{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
