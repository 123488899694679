import React from 'react';
import { Link } from 'react-router-dom';
import CommonBanner from '../../common/header/banner/CommonBanner';

export default function BusinessLoans() {
  return (
    <>
      <CommonBanner
        title='Business Loan'
        subtitle='Start your dream business'
      />

      <div className='more-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='more-info-content'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='left-image'>
                      <img src='/assets/images/more-info.jpg' alt='Car Loan' />
                    </div>
                  </div>
                  <div className='col-md-6 align-self-center'>
                    <div className='right-content'>
                      <span>Start your dream business</span>
                      <h2>
                        Business <em>Loan</em>
                      </h2>
                      <p>
                        Apply for unsecured and secured business loans for your
                        running business with lowest interest rate starting from
                        9%
                        <br />
                        <br />
                        Apply for unsecured and secured business loans for your
                        running business with lowest interest rate starting from
                        9%
                      </p>
                      <Link to='/services' className='filled-button'>
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
