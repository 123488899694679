import React, { useState } from 'react';
import sendMail from '../../common/api/Mail';

export default function CallBackForm() {
  const [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    [subject, setSubject] = useState(''),
    [message, setMessage] = useState('');
  //[phoneNumber, setPhoneNumber] = useState('');

  const send = (event) => {
    event.preventDefault();
    sendMail({ name, email, message, subject });
    clearForm();
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    //setPhoneNumber('');
  };
  return (
    <div className='callback-form'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-heading'>
              <h2>
                Request a <em>call back</em>
              </h2>
              <span>Drop a message and we will give a call back ASAP.</span>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='contact-form'>
              <form id='contact' onSubmit={send}>
                <div className='row'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='name'
                        type='text'
                        className='form-control'
                        id='name'
                        placeholder='Full Name'
                        required=''
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='email'
                        type='text'
                        className='form-control'
                        id='email'
                        pattern='[^ @]*@[^ @]*'
                        placeholder='E-Mail Address'
                        required=''
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <fieldset>
                      <input
                        name='subject'
                        type='text'
                        className='form-control'
                        id='subject'
                        placeholder='Subject'
                        required=''
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className='col-lg-12'>
                    <fieldset>
                      <textarea
                        name='message'
                        rows='6'
                        className='form-control'
                        id='message'
                        placeholder='Your Message'
                        required=''
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className='col-lg-12'>
                    <fieldset>
                      <button
                        type='submit'
                        id='form-submit'
                        className='border-button'
                      >
                        Send Message
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
