import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sendMail from '../api/Mail';

export default function Footer() {
  const [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    //[subject, setSubject] = useState(''),
    [message, setMessage] = useState('');
  //[phoneNumber, setPhoneNumber] = useState('');

  const send = (event) => {
    event.preventDefault();
    sendMail({ name, email, message });
    clearForm();
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    //setSubject('');
    setMessage('');
    //setPhoneNumber('');
  };

  return (
    <>
      <div className='partners'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='owl-partners owl-carousel'>
                <div className='partner-item'>
                  <img src='assets/images/client-01.png' title='1' alt='1' />
                </div>

                <div className='partner-item'>
                  <img src='assets/images/client-01.png' title='2' alt='2' />
                </div>

                <div className='partner-item'>
                  <img src='assets/images/client-01.png' title='3' alt='3' />
                </div>

                <div className='partner-item'>
                  <img src='assets/images/client-01.png' title='4' alt='4' />
                </div>

                <div className='partner-item'>
                  <img src='assets/images/client-01.png' title='5' alt='5' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div class='container'>
          <div class='row'>
            <div class='col-md-3 footer-item'>
              <h4>Finance Business</h4>
              <p>
                To make financial services easily accessible to every citizen of
                India. To become one of the top financial service distributors
                by creating an ideal environment that benefits all participants
                of the Financial Ecosystem, i.e. Service Providers,
                Representatives and Customers.
              </p>
              <ul class='social-icons'>
                <li>
                  <a rel='nofollow, noreferrer' href='/' target='_blank'>
                    <i class='fa fa-facebook'></i>
                  </a>
                </li>
                <li>
                  <a href='/'>
                    <i class='fa fa-twitter'></i>
                  </a>
                </li>
                <li>
                  <a href='/'>
                    <i class='fa fa-linkedin'></i>
                  </a>
                </li>
                <li>
                  <a href='/'>
                    <i class='fa fa-behance'></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class='col-md-3 footer-item'>
              <h4>Useful Links</h4>
              <ul class='menu-list'>
                <li>
                  <a href='/'>Vivamus ut tellus mi</a>
                </li>
                <li>
                  <a href='/'>Nulla nec cursus elit</a>
                </li>
                <li>
                  <a href='/'>Vulputate sed nec</a>
                </li>
                <li>
                  <a href='/'>Cursus augue hasellus</a>
                </li>
                <li>
                  <a href='/'>Lacinia ac sapien</a>
                </li>
              </ul>
            </div>
            <div class='col-md-3 footer-item'>
              <h4>Additional Pages</h4>
              <ul class='menu-list'>
                <li>
                  <Link to='/about'>About Us</Link>
                </li>
                <li>
                  <Link to='/about'>How We Work</Link>
                </li>
                <li>
                  <Link to='/contact'>Quick Support</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact Us</Link>
                </li>
                <li>
                  <a href='/'>Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div class='col-md-3 footer-item last-item'>
              <h4>Contact Us</h4>
              <div class='contact-form'>
                <form id='contact footer-contact' onSubmit={send}>
                  <div class='row'>
                    <div class='col-lg-12 col-md-12 col-sm-12'>
                      <fieldset>
                        <input
                          name='name'
                          type='text'
                          class='form-control'
                          id='name'
                          placeholder='Full Name'
                          required=''
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div class='col-lg-12 col-md-12 col-sm-12'>
                      <fieldset>
                        <input
                          name='email'
                          type='text'
                          class='form-control'
                          id='email'
                          pattern='[^ @]*@[^ @]*'
                          placeholder='E-Mail Address'
                          required=''
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div class='col-lg-12'>
                      <fieldset>
                        <textarea
                          name='message'
                          rows='6'
                          class='form-control'
                          id='message'
                          placeholder='Your Message'
                          required=''
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                      </fieldset>
                    </div>
                    <div class='col-lg-12'>
                      <fieldset>
                        <button
                          name='submit'
                          type='submit'
                          id='submit'
                          class='filled-button'
                        >
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div class='sub-footer'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-12'>
              <p>Copyright &copy; 2021 Finvestza</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
