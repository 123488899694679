import React from 'react';

export default function ServicesBanner() {
  return (
    <div class='page-heading header-text'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h1>Our Services</h1>
            <span>We are over 5 years of experience</span>
          </div>
        </div>
      </div>
    </div>
  );
}
