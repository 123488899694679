export default function sendMail({
  name = 'No Name',
  email = 'mail@vabhi.com',
  subject = 'No subject provided',
  message = 'No message provided',
  phoneNumber = '00 - 0000 0000 00',
}) {
  const params = new URLSearchParams(
    'name=' +
      name +
      '&email=' +
      email +
      '&subject=' +
      subject +
      '&message=' +
      message +
      '&phone_number=' +
      phoneNumber +
      '&submit='
  );
  // disable the send button
  fetch('/api.php', { method: 'POST', body: params })
    .then((e) => {
      if (e.status === '200') {
        console.log('Email Sent....');
      } else {
        console.log('Failed to send mail...');
      }
      return e;
    })
    .catch((e) => {
      console.log(e);
    });
}
