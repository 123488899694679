import React from 'react';

import { Link } from 'react-router-dom';
import CallBackForm from './forms/CallBackForm';

export default function ServicesPage() {
  return (
    <>
      <div className='services'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  Financial <em>Services</em>
                </h2>
                <span>
                  We provide all types of Loans. Checkout our all services here.
                </span>
              </div>
            </div>
            <Service heading='Home Loan' link='/services/home-loan'>
              A home loan is a loan that have endues to purchase of residential
              flat, bungalow, residential plot.
              <br />
              <br />
              <br />
            </Service>
            <Service heading='Mortgage Loan' link='/services/mortgage-loan'>
              The loan which is provided against any collateral mortgage
              property is called Property Mortgage Loan or Loan Against
              Property.
              <br />
              <br />
              <br />
            </Service>
            <Service
              heading='Purchase + Construction Loan'
              link='/services/purchase-construction-loan'
            >
              A purchase plus construction loan is a loan that have endues for
              construction and purchase of residential flat, bungalow,
              residential plot.
            </Service>
            <Service
              heading='Home Improvement Loan'
              link='/services/home-improvement-loan'
            >
              A Improvement loan is a loan that have endues to renovation of
              residential flat, bungalow, residential plot.
            </Service>
            <Service
              heading='Construction Loan'
              link='/services/construction-loan'
            >
              A construction loan is a loan that have endues for construction of
              residential flat, bungalow, residential plot.
            </Service>
            <Service heading='Plot Loan' link='/services/plot-loan'>
              A plot loan is a loan that have endues for purchase of residential
              and commercial plot.
              <br />
              <br />
            </Service>
            <Service heading='Car Loan' link='/services/car-loan'>
              A car loan is a loan that have endues purchase of new car
            </Service>
            <Service heading='Used Car Loan' link='/services/used-car-loan'>
              A used car loan is a loan that have endues purchase of used car
            </Service>
            <Service heading='Credit Cards' link='/services/credit-cards'>
              Apply for credit cards with large no of products including sbi
              cards
            </Service>
            <Service heading='Business Loans' link='/services/business-loans'>
              Apply for unsecured and secured business loans for your running
              business with lowest interest rate starting from 9%
            </Service>
            <Service heading='OD / CC' link='/services/od-cc'>
              Get an overdraft and cash credit limit upto Rs 1 crore.
              <br />
              <br />
            </Service>
            <Service
              heading='Loan Against Property'
              link='/services/loan-against-property'
            >
              The loan which is provided against any collateral mortgage
              property is called Loan Against Property.
            </Service>
            <Service heading='Private Funding' link='/services/private-funding'>
              We offer private funding to only selected cities of India.
            </Service>
            <Service
              heading='Commercial Vehicle Finance'
              link='/services/commercial-vehicle-finance'
            >
              We offer loans on purchase of commercial vehicles with multiple
              channel partners
            </Service>
            <Service
              heading='Government Loans/ MSME/ SIDBI'
              link='/services/government-loans-msme-sidbi'
            >
              Government Loans/ MSME/ SIDBI
            </Service>
            <Service heading='Project Loans' link='/services/project-loans'>
              We offer project loans for various projects including foreign
              funding.
            </Service>
            <Service heading='Personal Loan' link='/services/personal-loan'>
              For any urgent cash requirement, we prefer personal loan. It can
              be taken for any general purposes like education, reconstruction
              of property/home renovation, a wedding expense, vacation etc{' '}
            </Service>
          </div>
        </div>
      </div>

      <CallBackForm />
    </>
  );
}

function Service({
  heading = 'Heading',
  children = 'A brief description goes here',
  image = 'assets/images/service_03.jpg',
  link = '#',
}) {
  return (
    <div className='col-md-4' style={{ marginTop: '10px' }}>
      <div className='service-item'>
        <img src={image} alt='' />
        <div className='down-content'>
          <h4>{heading}</h4>
          <p>{children}</p>
          <Link
            to={link}
            className='filled-button'
            onClick={() => window.scrollTo(0, 0)}
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}
