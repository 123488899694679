import React from 'react';

export default function ContactBanner() {
  return (
    <div class='page-heading header-text'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h1>Contact Us</h1>
            <span>feel free to send us a message now!</span>
          </div>
        </div>
      </div>
    </div>
  );
}
