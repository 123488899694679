import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function NavBar() {
  const location = useLocation();
  let navToggle;

  return (
    <nav className='navbar navbar-expand-lg'>
      <div className='container'>
        <Link className='navbar-brand' to='/'>
          <h2>
            <img
              src='/assets/images/finvestza.png'
              style={{ width: '28px', height: '28px' }}
              alt='Finvesta Logo'
            ></img>{' '}
            Finvestza
          </h2>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarResponsive'
          aria-controls='navbarResponsive'
          aria-expanded='false'
          aria-label='Toggle navigation'
          ref={(btn) => (navToggle = btn)}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarResponsive'>
          <ul className='navbar-nav ml-auto'>
            <li
              className={
                'nav-item ' + (location.pathname === '/' ? 'active' : '')
              }
            >
              <Link
                className='nav-link'
                to='/'
                onClick={() => {
                  navToggle.click();
                  window.scrollTo(0, 0);
                }}
              >
                Home
                <span className='sr-only'>(current)</span>
              </Link>
            </li>
            <li
              className={
                'nav-item ' +
                (location.pathname.startsWith('/services') ? 'active' : '')
              }
            >
              <Link
                className='nav-link'
                to='/services'
                onClick={() => {
                  navToggle.click();
                  window.scrollTo(0, 0);
                }}
              >
                Our Services
              </Link>
            </li>
            <li
              className={
                'nav-item ' +
                (location.pathname === '/calculator' ? 'active' : '')
              }
            >
              <Link
                className='nav-link'
                to='/calculator'
                onClick={() => {
                  navToggle.click();
                  window.scrollTo(0, 0);
                }}
              >
                Loan Calculator
              </Link>
            </li>
            <li
              className={
                'nav-item ' + (location.pathname === '/about' ? 'active' : '')
              }
            >
              <Link
                className='nav-link'
                to='/about'
                onClick={() => {
                  navToggle.click();
                  window.scrollTo(0, 0);
                }}
              >
                About Us
              </Link>
            </li>
            <li
              className={
                'nav-item ' + (location.pathname === '/contact' ? 'active' : '')
              }
            >
              <Link
                className='nav-link'
                to='/contact'
                onClick={() => {
                  navToggle.click();
                  window.scrollTo(0, 0);
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
