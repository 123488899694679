import React from 'react';
import { Link } from 'react-router-dom';
import CommonBanner from '../../common/header/banner/CommonBanner';

export default function LoanAgainstProperty() {
  return (
    <>
      <CommonBanner title='Car Loan' subtitle='Get you dream car' />

      <div className='more-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='more-info-content'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='left-image'>
                      <img src='/assets/images/more-info.jpg' alt='Car Loan' />
                    </div>
                  </div>
                  <div className='col-md-6 align-self-center'>
                    <div className='right-content'>
                      <span>Get your dream car</span>
                      <h2>
                        Car <em>Loan</em>
                      </h2>
                      <p>
                        A car loan is a loan that have endues purchase of new
                        car.
                        <br />
                        <br />A car loan is a loan that have endues purchase of
                        new car.
                      </p>
                      <Link to='/services' className='filled-button'>
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
