import React from 'react';
import { Link } from 'react-router-dom';
import CommonBanner from '../common/header/banner/CommonBanner';
import LoanCalculatorForm from './forms/LoanCalculatorForm';

export default function LoanCalculatorPage() {
  return (
    <>
      <CommonBanner
        title='Loan Calculator'
        subtitle='Find the best of the best values we provide'
      />

      <div className='request-form'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h4>Not the best?</h4>
              <span>Do you think our values are not best? Challenge Us!</span>
            </div>
            <div className='col-md-4'>
              <Link to='/contact#contact' className='border-button'>
                Tell the now
              </Link>
            </div>
          </div>
        </div>
      </div>

      <LoanCalculatorForm />
    </>
  );
}
