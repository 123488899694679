import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sendMail from '../common/api/Mail';

export default function ContactPage() {
  const [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    [subject, setSubject] = useState(''),
    [message, setMessage] = useState('');
  //[phoneNumber, setPhoneNumber] = useState('');

  const send = (event) => {
    event.preventDefault();
    sendMail({ name, email, message, subject });
    clearForm();
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    //setPhoneNumber('');
  };

  return (
    <>
      <div className='contact-information'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='contact-item'>
                <i className='fa fa-whatsapp'></i>
                <h4>Whatsapp</h4>
                <p>
                  Feel free to send us a message now or call us in working
                  hours.
                </p>
                <a href='https://wa.me/919876543210?text=Hello%20Finvestza%2C%0AI%20am%20intrested%20in%20your%20services.%0AMy%20Name%20is%20.....'>
                  +91 987-654-3210
                </a>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='contact-item'>
                <i className='fa fa-envelope'></i>
                <h4>Email</h4>
                <p>Send us an email with your query now.</p>
                <br />
                <Link to='/contact'>info@company.com</Link>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='contact-item'>
                <i className='fa fa-map-marker'></i>
                <h4>Location</h4>
                <p>
                  E-11 JanakPuri 1st Lalkothi
                  <br />
                  Jaipur, Rajisthan 302015
                </p>
                <a href='#map'>View on Google Maps</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='callback-form contact-us'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='section-heading'>
                <h2>
                  Send us a <em>message</em>
                </h2>
                <span>Suspendisse a ante in neque iaculis lacinia</span>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='contact-form'>
                <form id='contact' onSubmit={send}>
                  <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                      <fieldset>
                        <input
                          name='name'
                          type='text'
                          className='form-control'
                          id='name'
                          placeholder='Full Name'
                          required=''
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                      <fieldset>
                        <input
                          name='email'
                          type='text'
                          className='form-control'
                          id='email'
                          pattern='[^ @]*@[^ @]*'
                          placeholder='E-Mail Address'
                          required=''
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                      <fieldset>
                        <input
                          name='subject'
                          type='text'
                          className='form-control'
                          id='subject'
                          placeholder='Subject'
                          required=''
                          value={subject}
                          onChange={(event) => setSubject(event.target.value)}
                        />
                      </fieldset>
                    </div>
                    <div className='col-lg-12'>
                      <fieldset>
                        <textarea
                          name='message'
                          rows='6'
                          className='form-control'
                          id='message'
                          placeholder='Your Message'
                          required=''
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                        ></textarea>
                      </fieldset>
                    </div>
                    <div className='col-lg-12'>
                      <fieldset>
                        <button
                          type='submit'
                          id='form-submit'
                          className='filled-button'
                        >
                          Send Message
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.8052382965943!2d75.79188744297811!3d26.88816147734512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb831dd23508f5d25!2sGopati%20Atul%20%26%20Co.!5e0!3m2!1sen!2sin!4v1630811999436!5m2!1sen!2sin'
          width='100%'
          height='500px'
          frameborder='0'
          style={{ border: 0 }}
          allowfullscreen
          title='map'
        ></iframe>
      </div>
    </>
  );
}
